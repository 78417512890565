import React from 'react'
import * as styles from './styles.module.scss'
import icon from '../../../../assets/icons/business.svg'
import { Link } from 'gatsby';

export const ArticleCard = ({ article }) => {
  const { groupTitle, guideDocs } = article;
  return (
    <Link to={`/da/guide/${guideDocs[0]?.uri}`} className={styles.card}>
      <img src={icon} alt="card icon" width={32} />
      <div className={styles.content}>
        <h6>{groupTitle}</h6>
        <span>{guideDocs?.length} artikler</span>
      </div>
    </Link>
  )
}
