import React, { useMemo, useState } from "react";
import { ScreenContainer } from "../common/containers/ScreenContainer";
import { ArticleCard } from "./common/ArticleCard";
import * as styles from "./styles.module.scss";
import plus from "../../assets/icons/plus.svg";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import helpIcon from "../../assets/icons/helpIcon.svg";
import { Link } from "gatsby";

export const Articles = ({ articles }) => {
  const [viewMore, setViewMore] = useState(false);
  const hiddenArticles = useMemo(() => articles.slice(3), [articles]);
  return (
    <section>
      <ScreenContainer>
        <div className={styles.ourArticles}>
          <h2>Vores artikler</h2>
          <h6>Få det bedste ud af holdingselskabet</h6>
          <div className={styles.allArticles}>
            {articles.slice(0, 3).map((articleGroup) => (
              <ArticleCard
                article={articleGroup}
                key={articleGroup.groupTitle}
              />
            ))}
          </div>
          <div
            className={clsx(styles.allArticles, {
              [styles.hidden]: !viewMore,
            })}
          >
            {hiddenArticles.map((articleGroup) => (
              <ArticleCard
                article={articleGroup}
                key={articleGroup.groupTitle}
              />
            ))}
          </div>
          <button
            className={styles.toggleView}
            onClick={() => setViewMore(!viewMore)}
          >
            <img src={plus} alt="plus" />
            VIS {hiddenArticles.length} {viewMore ? "MINDRE" : "MERE"}
          </button>
        </div>
        <div className={styles.blueArticles}>
          <StaticImage
            src="../../assets/icons/question.png"
            alt="question"
            placeholder="tracedSVG"
            className={styles.questionIcon}
            imgStyle={{ objectFit: "contain" }}
          />

          <div className={styles.articleColumns}>
            <div className={styles.list}>
              <div className={styles.title}>
                <img src={helpIcon} alt="help" />
                <h6>Tid til årsrapport?</h6>
              </div>
              <ul>
                <li>
                  <Link to="/da/guide/praksis_krav">Bogføring</Link>
                </li>
                <li>
                  <Link to="/da/guide/skat_selskabsskat">Selskabsskat</Link>
                </li>
                <li>
                  <Link to="/da/guide/holdingselskab_og_sambeskatning">
                    Sambeskatning
                  </Link>
                </li>
                <li>
                  <Link to="/da/guide/bogforing_til_arsrapport">
                    Årsrapport
                  </Link>
                </li>
                <li>
                  <Link to="/da/guide/praksis_selskabets_selvangivelse">
                    Selvangivelse (oplysningsskema)
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.list}>
              <div className={styles.title}>
                <img src={helpIcon} alt="help" />
                <h6>Hvad gør jeg for at....?</h6>
              </div>
              <ul>
                <li>
                  <Link to="/da/guide/penge_udbytte">Udbetale udbytte</Link>
                </li>
                <li>
                  <Link to="/da/guide/penge_loen">Udbetale løn</Link>
                </li>
                <li>
                  <Link to="/da/guide/penge_gave_til_born">
                    Overdrage til mine børn
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.list}>
              <div className={styles.title}>
                <img src={helpIcon} alt="help" />
                <h6>Hvordan investerer jeg i?</h6>
              </div>
              <ul>
                <li>
                  <Link to="/da/guide/invest_unoteret">
                    Ejerandele i unoterede selskaber
                  </Link>
                </li>
                <li>
                  <Link to="/da/guide/invest_noteret">
                    Aktier og andre værdipapirer
                  </Link>
                </li>
                <li>
                  <Link to="/da/guide/invest_ejendomme">Ejendomme</Link>
                </li>
                <li>
                  <Link to="/da/guide/invest_privateequity">
                    Private equity
                  </Link>
                </li>
                <li>
                  <Link to="/da/guide/invest_alternativer">Alternativer</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ScreenContainer>
    </section>
  );
};
