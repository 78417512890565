import React from "react";
import { Layout } from "../components/Layout";
import { graphql } from "gatsby";
import { Articles } from "../components/Articles";
import './styles.module.scss';

const IndexPage = ({ data }) => {
  return (
    <>
      <Layout bgImage={data.file} articles={data.guideNodeMain.articleGroups}>
        <Articles articles={data.guideNodeMain.articleGroups} />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`query Images {
  file(relativePath: {eq: "bg/main_full.jpg"}) {
    id
    childImageSharp {
      fluid(quality: 90, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  guideNodeMain(id: {eq: "guide main"}) {
    articleGroups {
      groupTitle
      guideDocs {
        uri
        contents
        groupId
        guideTitle
      }
      id
    }
  }
}
`
